import angular from 'angular';
import { ClientFeatureFlagService } from 'knock-utils/feature-flag/client-feature-flag-service';
import FeatureFlagScope from 'knock-utils/feature-flag/feature-flag-scope';
import LaunchDarklyClientProvider from 'knock-utils/feature-flag/launch-darkly-client-provider';

// This service initialzes feature flags using the knock-utils library, an in-house wrapper for our
// external feature flag service. The exported featureFlagService will have one const and two methods available.

// Available Const:
// ** FEATURES **, which maps all available feature flags, to avoid using string literal flag names outside this file

// Available Methods:
// ** featureFlagService.initialize(new FeatureFlagScope(leasingTeamId)) ** initializes the feature flag for a given scope
// ** featureFlagService.get(FEATURES.flagName, defaultValue) ** will get the value of a flag for that scope.

// https://github.com/knockrentals/knock-utils/blob/master/src/feature-flag/client-feature-flag-service.ts

(function () {
  var app = angular.module('knockApp');

  app.factory('featureFlagService', [
    'LAUNCHDARKLY_CLIENTSIDE_ID',
    'userService',
    'leasingTeamApi',
    function (LAUNCHDARKLY_CLIENTSIDE_ID, userService, leasingTeamApi) {
      var self = this;
      let _featureFlagsInitialized = false;

      // Map of available feature flag vars to string literal names
      self.FEATURES = {
        NEW_UNITS: 'new-units',
        QUOTE_UPCHARGES: 'quote-upcharges',
        USE_COOKIE_FOR_TOKEN: 'use-cookie-for-token',
        ENHANCED_QUOTES_MODAL: 'enhanced-quotes-modal',
        SHOW_VOICE_TRANSCRIPTIONS: 'show-voice-transcriptions',
        PROSPECT_RESIDENT_PAGINATION: 'prospect-resident-pagination',
        CLAW_AVAILABLE: 'claw-available',
        PROSPECT_PAGE_REDESIGN: 'prospect-page-redesign',
        COMPANY_QUICK_REPLIES: 'company-quick-replies',
        RESIDENT_PAGE_REDESIGN: 'resident-page-redesign',
        APPLICATION_LEASING_PROVIDER: 'application-leasing-provider',
        DEMAND_X_DEMO: 'lma-demand-x-demo',
        CROSS_SELL_AUTOMATION: 'lma-cross-sell-automation',
        ENHANCED_LEASING_BINDER: 'lma-leasing-binder-enhancements',
        VOICE_APP_FOR_OUTBOUND_CALLS: 'voice-app-for-outbound-calls',
        LMA_NEW_NAVIGATION: 'lma-new-navigation',
        LMA_DASHBOARD_REDESIGN: 'lma-dashboard-redesign',
        LMA_COMPLETE_TOUR: 'lma-complete-tour',
        DEMAND_X_PRIORITY: 'demand-x-priority',
        BULK_COMMUNICATION_ENHANCEMENT: 'lma-bulk-communication-enhancements',
        LMA_PLAID_IDV: 'lma-plaid-idv',
        OSM_LIGHT_GUESTCARD_LINK: 'osm-light-guestcard-link',
        AGENT_ATTRIBUTION: 'lma-agent-attribution',
        ONESITE_APPLICANT_GUESTCARD_LINK: 'onesite-applicant-guestcard-link',
        LMA_VOICEAPP_STATUS_ENHANCEMENT: 'lma-voiceapp-status-enhancement',
        CALL_INTEL_STANDALONE: 'call-intel-standalone',
        PMS_LOCK_PROSPECT_GUESTCARD_IN_APPLIED_STATE:
          'pms-lock-prospect-guestcard-in-applied-state'
      };

      // knock-utils/ LD Client provider
      let _provider = null;

      // ClientFeatureFlagService as _featureFlagService
      let _featureFlagService = null;

      const _getFeatureFlagScope = async () => {
        // retrieve the leasing team the user is currently accessing
        let leasingTeam;
        await leasingTeamApi.getMyLeasingTeam().success(function (response) {
          leasingTeam = response.leasing_team;
        });

        if (leasingTeam) {
          // if we were able to retrieve a scoped leasing team, use the leasing
          // team record to set the LaunchDarkly user scope
          return [leasingTeam.id, leasingTeam.company_id];
        }

        // retrieve the authenticated user
        const user = await userService.getScopedUser();

        if (
          !user ||
          !user.group_ids ||
          !Array.isArray(user.group_ids) ||
          user.group_ids.length !== 1
        ) {
          return null;
        }

        // if we were unable to retrieve the scoped leasing team but were able
        // to retrieve the authenticated user, use the authenticated user to set
        // the LaunchDarkly user scope
        return [user.group_ids[0], user.company_id];
      };

      self.initialize = async () => {
        // Instantiate the knock-utils/ LD Client provider
        _provider = new LaunchDarklyClientProvider(LAUNCHDARKLY_CLIENTSIDE_ID);

        // Instantiate ClientFeatureFlagService as _featureFlagService
        _featureFlagService = new ClientFeatureFlagService(_provider);

        // Initialize the feature flag service based on a user's leasing_team_id/ group_id
        const userData = await _getFeatureFlagScope();
        if (!userData) {
          console.error('User not found. Feature flags not initialized.');
          _featureFlagsInitialized = false;
          return;
        }

        try {
          await _featureFlagService.initialize(
            new FeatureFlagScope(userData[0], {
              company: userData[1],
              service: 'WebFront'
            })
          );
          console.log('featureFlagService initialized for user ', userData[0]);
          _featureFlagsInitialized = true;
        } catch (e) {
          console.error('Error initializing Feature Flag Service: ', e);
          _featureFlagsInitialized = false;
        }
      };

      self.get = async (featureName, defaultValue) => {
        // Get the value of a feature flag for an initialized user
        if (!_featureFlagsInitialized) {
          await self.initialize();
        }

        try {
          const flagValue = await _featureFlagService.get(
            featureName,
            defaultValue
          );

          return flagValue;
        } catch (e) {
          console.error(
            `Error getting feature flag value for ${featureName}. Setting to default value ${defaultValue}: ${e}`
          );
          return defaultValue;
        }
      };

      return self;
    }
  ]);
})();
