import React, { FC } from 'react';

import { Provider } from 'react-redux';
import { AngularContextProvider } from '../AngularContextProvider';
import { store } from 'app/store';

import { ConversationEmailBody } from './ConversationEmailBody';

import { CCRecipient, OwnerInfo } from '../../ts/models';

export interface ConversationEmailProps {
  toName: string;
  toEmailAddress: string;
  ownerInfo: OwnerInfo;
  defaultSubject: string;
  defaultRecipients: CCRecipient[];
  emailDomainBlocklist?: string[];
  onChangeSubject: (subject: string) => void;
  onChangeRecipients: (ccRecipients: CCRecipient[]) => void;
  prospectId: number;
  isBulkCommunicationEnhancementEnabled: boolean;
  loggedinUser: OwnerInfo;
  setSendAsUserId: (userId: number) => void;
  isAgentAttributionEnable: boolean;
}

export const ConversationEmail: FC<ConversationEmailProps> = ({
  toName,
  toEmailAddress,
  ownerInfo,
  defaultSubject,
  defaultRecipients,
  emailDomainBlocklist,
  onChangeSubject,
  onChangeRecipients,
  prospectId,
  isBulkCommunicationEnhancementEnabled,
  loggedinUser,
  setSendAsUserId,
  isAgentAttributionEnable
}) => {
  return (
    <AngularContextProvider
      context={{
        prospectId
      }}
    >
      <Provider store={store}>
        <ConversationEmailBody
          toName={toName}
          toEmailAddress={toEmailAddress}
          ownerInfo={ownerInfo}
          defaultSubject={defaultSubject}
          defaultRecipients={defaultRecipients}
          emailDomainBlocklist={emailDomainBlocklist}
          onChangeSubject={onChangeSubject}
          onChangeRecipients={onChangeRecipients}
          prospectId={prospectId}
          isBulkCommunicationEnhancementEnabled={
            isBulkCommunicationEnhancementEnabled
          }
          loggedinUser={loggedinUser}
          setSendAsUserId={setSendAsUserId}
          isAgentAttributionEnable={isAgentAttributionEnable}
        />
      </Provider>
    </AngularContextProvider>
  );
};
